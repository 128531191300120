import { ENDPOINTS } from '../src/constants'
import axios from '../src/axios'
import { TrackingCase } from './trackingCases'
import { tracker } from '../src/systemLogs'

export const toggleIsExtensionDownloaded = async (userId: any, extensionDownloaded: boolean) => {
  tracker.track(TrackingCase.UserTracking, `PATCH ${ENDPOINTS.user}`,{location: 'toggleIsExtensionDownloaded',
  extensionDownloaded})
  try {
    await axios.patch(ENDPOINTS.user.replace(':id', userId), {
      extensionDownloaded,
    })
  } catch (err) {
    return
  }
}
